import React, { useState } from "react"
import styled from "styled-components"
import { fluidRange } from "polished"
import { Photo as _Photo } from "../images/photos"
import { H4, P } from "../common/typography"
import { getFromTheme as theme } from "../../utils/styles"
import { UnderlineButtonProps } from "../buttons/underline"
import Button from "../buttons"
import moment from "moment"

const TruncatingP = styled(P)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const PhotoWrapper = styled.div`
  overflow: hidden;
  border-radius: ${theme("borderRadius.cards")}px
    ${theme("borderRadius.cards")}px 0 0;

  img {
    border-radius: ${theme("borderRadius.cards")}px
      ${theme("borderRadius.cards")}px 0 0;
  }
`

const DateCaption = styled(P)``

const Captions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${fluidRange(
    [
      {
        prop: "padding-left",
        fromSize: "24px",
        toSize: "48px",
      },
      {
        prop: "padding-right",
        fromSize: "24px",
        toSize: "48px",
      },
      {
        prop: "padding-top",
        fromSize: "32px",
        toSize: "52px",
      },
      {
        prop: "padding-bottom",
        fromSize: "40px",
        toSize: "60px",
      },
    ],
    "320px",
    "640px"
  )};

  border-radius: 0 0 ${theme("borderRadius.cards")}px
    ${theme("borderRadius.cards")}px;

  transition: box-shadow 0.2s;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);

  ${DateCaption} {
    align-self: flex-end;
  }

  > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Wrapper = styled.div`
  max-width: 475px;
  transition: transform 0.2s;

  &.clickable {
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);

      ${Captions} {
        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.07);
      }
    }

    &:active {
      transition: transform 0s;
      transform: translateY(0px);
    }
  }
`

interface ArticleProps {
  PhotoClass?: _Photo
  photo?: JSX.Element
  title: string
  description?: string
  date?: moment.Moment
  buttonProps?: UnderlineButtonProps
  onClick?: (..._: any[]) => any
}

const Article = ({
  PhotoClass,
  photo,
  title,
  description,
  buttonProps,
  date,
  onClick,
}: ArticleProps) => {
  const [isHovered, setHovered] = useState(false)

  if (buttonProps) {
    buttonProps.onClick = onClick
    buttonProps.hovered = isHovered
  }

  return (
    <Wrapper
      className={onClick ? "clickable" : ""}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <PhotoWrapper>
        {PhotoClass ? <PhotoClass /> : photo ? photo : <></>}
      </PhotoWrapper>
      <Captions>
        {date ? <DateCaption xsmall>{date.format("LL")}</DateCaption> : <></>}
        <H4>{title}</H4>
        {description ? <TruncatingP small>{description}</TruncatingP> : <></>}
        {buttonProps ? <Button.Underline {...buttonProps} /> : <></>}
      </Captions>
    </Wrapper>
  )
}

export default Article
