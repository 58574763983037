import React from "react"
import styled from "styled-components"
import { injectIntl, navigate, useIntl } from "gatsby-plugin-intl"

import BlogPhotos from "../../images/blogPhotos"
import moment from "moment"
import { localizedGetter } from "../../../utils/localized"
import { postExcerpt } from "../../../utils/excerpt"
import Article from "../../blocks/article"

const Container = styled.div`
  margin: 0;
`

const Post = ({ post }) => {
  const intl = useIntl()
  const get = localizedGetter(intl, post)

  const navigateToPost = (e) => {
    navigate(`/blog/${get("slug")}/`)
  }

  return (
    <Container>
      <Article
        title={get("title")}
        description={postExcerpt(post, intl)}
        buttonProps={{
          text: intl.formatMessage({ id: "blog.teaser.readMore" }),
          color: "accent",
          buttonStyle: "navigate",
        }}
        date={moment(post.createdDate)}
        onClick={navigateToPost}
        photo={BlogPhotos[post.id]}
      />
    </Container>
  )
}

export default Post
