import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { FormattedMessage, IntlProvider, useIntl } from "gatsby-plugin-intl"

import { styled } from "../utils/styles"
import Seo from "../components/common/seo"
import { H1, H6, P } from "../components/common/typography"
import Layout from "../components/site/layout"
import Post from "../components/pages/blog/post"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { getLocale, getPathName, messagesForLanguage } from "../utils/localized"
import { Section as _Section, PageContent } from "../components/common/layout"
import theme from "../utils/theme"
import SignUp from "../components/blocks/signUp"

const ArticlesGrid = styled(ResponsiveMasonry)``

const Title = styled.div`
  grid-column: 1/-1;
`

const Section = styled(_Section)`
  & > div > div,
  & > div > div > div {
    width: auto !important;
  }
`

const HeaderSection = styled(Section)`
  max-width: 1600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
`

const BlogPage = ({ pageContext }) => {
  const { locale } = useIntl()
  const { [locale]: context } = pageContext
  const { currentPage, isEmpty, items } = context

  useEffect(() => {
    if (isEmpty) {
      navigate(
        `/${locale}/blog${currentPage === 2 ? "" : `/${currentPage - 1}`}`
      )
    }
  })

  if (!context.isEmpty) {
    return (
      <Layout>
        <PageContent>
          <HeaderSection>
            <Title>
              <H6 margin="small">
                <FormattedMessage id="blog.supTitle" />
              </H6>
              <H1 fluid margin="small">
                <FormattedMessage id="blog.title" />
              </H1>
              <P>
                <FormattedMessage id="blog.subtitle" />
              </P>
            </Title>
          </HeaderSection>
          <Section>
            <ArticlesGrid
              columnsCountBreakPoints={{
                [theme.breakpoints.xsmall]: 1,
                [theme.breakpoints.medium]: 2,
                [theme.breakpoints.xlarge]: 3,
              }}
            >
              <Masonry gutter="32px">
                {items.map((post) => (
                  <Post post={post} />
                ))}
              </Masonry>
            </ArticlesGrid>
          </Section>
          <Section t-lg h-centered b-md h-m-sm>
            <SignUp />
          </Section>
        </PageContent>
      </Layout>
    )
  } else {
    return <></>
  }
}

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo
        titleId="blog.supTitle"
        path={getPathName()}
        descriptionId="blog.title"
      />
    </IntlProvider>
  )
}

export default BlogPage
